@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables";


html {
    scroll-behavior: smooth;
}

body {
    // background-color: #f4f5f7;
    font-size: 14px;
    // font-family: "Open Sans", sans-serif;
    font-family: "Nunito", sans-serif;

    .word-breaker {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
    }

    &.on-joyride {
        overflow: hidden;
        height: 100vh;
    }

    a {
        color: var(--theme-primary-color);
    }

    .pensil-card {
        border-radius: 4px;
    }

    .IconButton2 {
        position: relative;

        .unread-count {
            position: absolute;
            background-color: var(--theme-primary-color);
            color: white;
            padding: 3px;
            display: flex;
            width: 16px;
            justify-content: center;
            align-items: center;
            top: 4px;
            right: 4px;
            height: 16px;
            border-radius: 50%;
            font-size: xx-small;
        }
    }

    .CreateGroupBody {
        .group-color-preview-holder {
            padding: 5px;
            border-width: 1px;
            border-style: dashed;

            .group-color-preview {
                transition: all 0.2s;
                background-size: cover;
                background-position: center;
                padding-top: 41.66%;
            }
        }

        .color-selector {
            position: relative;
            width: 24px;
            height: 24px;
            margin: 6px;
            border-radius: 50%;
            cursor: pointer;

            &::after {
                transition: all 0.2s;
                display: block;
                content: "";
                width: 15px;
                height: 10px;
                border: 2px solid transparent;
                position: relative;
                top: 4px;
                left: 4px;
                transform: rotate(-45deg);
            }

            &.selected {
                box-shadow: 0px 0 5px 0 #0004;

                &::after {
                    border-bottom-color: white;
                    border-left-color: white;
                }
            }

            &.banner {
                border: 1px solid var(--theme-subtitle-1-text-color);
                width: 22px;
                height: 22px;
                padding: 3px;

                &.selected {
                    border: 1px solid black;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .OTPBox {
        display: flex;
        justify-content: space-between;

        .OTPField {
            padding: 0.5rem;
            box-sizing: border-box;
            width: 28px;
            font-weight: 700;
            font-size: large;

            // color: #404040;
            // border-bottom: 1px solid #c4c4c4;
            &:focus {
                outline: none;
            }

            // &.filled
            // &:hover {
            //     border-bottom: 1px solid $pensilBlue;
            // }
        }
    }

    .CommunityThemeSelector {
        .theme-preview {
            .unselected-icon {
                display: block;

                &.dark {
                    opacity: 0.85;
                }
            }

            .selected-icon {
                display: none;
            }

            &:hover,
            &.selected {
                .unselected-icon {
                    display: none;
                }

                .selected-icon {
                    display: block;
                }
            }
        }
    }

    .CreateCommunityPage {
        .card-success {
            padding: 16rem 1.5rem 2.5rem;
            margin-top: 2rem;
            border-radius: 10px;
            box-shadow: 0px 0px 25px 0px #00000029;
            overflow: hidden;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -10rem;
                width: 20rem;
                height: 20rem;
                border: 8rem solid #f2f2f2;
                left: 1rem;
                transform: rotate(-45deg);
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -2.5rem;
                width: 20rem;
                height: 10rem;
                border: 4rem solid #28b446;
                border-top: none;
                border-right: none;
                left: 7rem;
                transform: rotate(-45deg);
            }

            .address {
                color: #28b446;
                font-weight: bold;
                font-size: large;
            }

            .success-message {
                margin-top: 1.5rem;
            }
        }
    }

    .AddressFAQ {
        position: relative;

        .address-faq {
            display: none;
            position: absolute;
            width: 300px;
            right: 0;
            bottom: 32px;
        }

        &:hover {
            .address-faq {
                display: block;
            }
        }
    }

    .LoginPage,
    .CommunityTypeSelection,
    .CreateCommunityPage,
    .ProfileUpdate {
        .card {
            padding: 2.5rem 1.5rem;
            margin-top: 2rem;
            border-radius: 10px;
            box-shadow: 0px 0px 25px 0px #00000029;

            .or {
                position: relative;
                margin: auto;
                text-align: center;
                width: 20px;

                .text {
                    color: #898989;
                }

                .line {
                    content: "";
                    flex-shrink: 0;
                    margin: 24px 18px;
                    display: block;
                    width: 48px;
                    height: 3px;
                    border-bottom: 1px solid #d1d1d1;
                }
            }

            .login-with-google {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--theme-border-color)
            }
        }
    }

    .LoginPage {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        // background-color: white;
        .pensil-logo {
            width: 100%;
            max-height: 108px;
            display: flex;
            justify-content: center;
            margin-bottom: 1.5rem;

            img {
                max-height: 108px;
            }
        }

        .card {
            box-shadow: none;
            // background-color: white;
            // padding: 2.5rem 1.5rem;
        }
    }

    .ProfileUpdate {
        .PictureUpdate {
            cursor: pointer;
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .preview-control,
            .upload-control {
                overflow: hidden;
                border-radius: 50%;
                width: 96px;
                height: 96px;
            }
        }
    }

    .CommunitySettingsSidebar {
        .SidebarHeader {
            font-size: initial;
            font-weight: 600;
            padding: 8px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            border-radius: 6px;
            padding-left: 32px;
            position: relative;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
        }

        .SidebarLink {
            font-weight: 400;
            padding: 8px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            border-radius: 4px;
            padding-left: 32px;
            position: relative;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
        }
    }

    .CreateCommunityPage {
        .PictureUpdate {
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2rem 0;
            flex-direction: column;

            .upload-control {
                border: 2px dashed #ddd;
                border-radius: 5px;
                overflow: hidden;
                width: 96px;
                flex-direction: column;
                height: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .Header {
        position: fixed;
        top: 0;
        z-index: 120;
        left: 0;
        right: 0;

        .header-container {
            display: grid;
            // grid-template-columns: 300px 1fr 300px;
            grid-template-areas: "main_logo search_bar other_options";
            grid-column-gap: 40px;
            max-width: 1340px;
            margin: auto;

            .mobile-header-hamburger {
                display: none;
                width: 25px;
                height: 25px;
                justify-content: center;
                align-items: center;
                margin-right: 0.5rem;
                cursor: pointer;
            }

            .MainLogo {
                grid-area: main_logo;
                margin-left: 36px;

                .main-logo {
                    max-width: 42px;
                    max-height: 42px;
                }
            }

            .SearchBar {
                grid-area: search_bar;
            }

            .OtherOptions {
                grid-area: other_options;
            }
        }

        .Avatar {
            &::after {
                content: "";
                display: block;
                width: 0px;
                height: 0px;
                border: 5px solid #aaa;
                border-top-color: transparent;
                border-left-color: transparent;
                transform: rotate(45deg);
                margin-bottom: 5px;
                margin-left: 8px;
            }
        }

        .Navbar {
            .NavbarButton {
                padding: 2px 12px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .icon {
                    display: block;
                }

                .active-icon {
                    display: none;
                }

                &.active,
                &:hover {
                    background-color: $pensilBlue;
                    color: white;

                    .icon {
                        display: none;
                    }

                    .active-icon {
                        display: block;
                    }
                }
            }
        }

        .dropdown {
            position: absolute;
            top: 48px;
            right: 0px;
            // width: 160px;

            .dropdown-button {
                cursor: pointer;
                padding: 8px 16px;
                // width: 100%;
                margin: 2px 5px;
                border-radius: 5px;

                &:hover {
                    background-color: #aaa2;
                }
            }
        }
    }

    .CommunityMobileAppPopup {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        padding: 1rem;
        display: none;
    }

    .DualVerificationPopup {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        padding: 0.3rem 1rem;
    }

    .Avatar {
        position: relative;

        .extra-info {
            // color: #3d3d3d;
            font-size: 0.65rem;
        }

        .update-marker {
            position: absolute;
            // top: 0;
            right: 5px;
            width: 16px;
            margin-left: 5px;
            height: 16px;
            border-radius: 50%;
            background-color: red;
            padding: 2px;
            color: white;
            font-size: 0.8rem;
            text-align: center;
            line-height: 12px;
            font-weight: 700;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
        }

        &.details {
            overflow: hidden;
            box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 0;
            z-index: 1;
            width: 240px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .extra-info-holder {
                .extra-info {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 2px;
                    display: flex;
                    align-items: center;
                    word-break: keep-all;
                    line-break: strict;

                    img {
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .IconButton {
        .default-icon {
            display: block;
        }

        .hover-icon {
            display: none;
        }

        &.has-hover-icon {
            &:hover {
                .default-icon {
                    display: none;
                }

                .hover-icon {
                    display: block;
                }
            }
        }
    }

    .GroupTab {
        width: 100%;
        position: relative;

        .group-tab-button {
            width: 24px;
            height: 24px;
            top: 12px;
            font-weight: 900;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: #0002;
                border-radius: 50%;
            }

            &.prev {
                left: 9px;
            }

            &.next {
                right: 9px;
            }
        }

        .GroupTabPill {
            position: relative;

            &:hover,
            &.active {
                color: $pensilBlue;

                &::after {
                    content: "";
                    display: block;
                    background-color: $pensilBlue;
                    height: 4px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 4px;
                }
            }
        }
    }

    .CreatePostCard {
        &.active {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            overflow: auto;
            z-index: 200;
            background-color: #3338;

            .Card {
                margin: 10rem auto;
                max-width: 540px;
            }
        }

        .tags-container {
            display: flex;
            flex-wrap: wrap;

            .tag-label {
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
                    "Open Sans", "Helvetica Neue", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.025em;
                text-align: left;
                color: #000000;
                opacity: 0.5;
                margin: 0px !important;
                // margin-bottom: 12px !important;
            }

            .tag-body-selected {
                background: #0445fe;
                border: 1px solid #0445fe;
                border-radius: 12px;
                padding: 3px 10px;
                overflow: hidden;
                cursor: pointer;

                .tag-title {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
                        "Open Sans", "Helvetica Neue", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0.025em;
                    text-align: left;
                    color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .tag-body {
                background: #f2f2f2;
                border: 1px solid #dadada;
                box-sizing: border-box;
                border-radius: 12px;
                padding: 3px 10px;
                margin-bottom: unset !important;
                overflow: hidden;
                cursor: pointer;

                .tag-title {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
                        "Open Sans", "Helvetica Neue", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0.025em;
                    text-align: left;
                    color: #393939;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .PostCard {
        border-radius: 4px;

        .tags-container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .tag-body {
                box-sizing: border-box;
                border-radius: 12px;
                padding: 3px 10px;
                margin-bottom: 10px;
            }
        }

        .PostEmbedCodeViewer {
            width: 100%;
        }
    }

    .TagPage {
        .search-tag-icon {
            transform: rotate(270deg);
        }
    }


    .RealtimeSectionDetail {
        height: calc(94vh - 172px);
        overflow-y: auto;
        position: relative;
    }

    .RealtimeCreatePostCard {
        position: sticky;
        right: 0px;
        // background-color: white;
        bottom: 0px;
        left: 0px;

        textarea {
            resize: none;
        }
    }

    .Modal {
        display: none;

        &.active {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            overflow: auto;
            z-index: 300;
            background-color: #3338;
            display: block;
        }

        .Card {
            margin: 10rem auto;
        }
    }

    .CreateTagModal {
        .modal-header {
            background-color: #f3f5fa;
        }

        .modal-body {
            max-height: calc(100vh - 450px) !important;
            overflow: auto;

            .modal-tag-container {
                padding-top: 10px;
                padding-bottom: 10px;

                .tag-title {
                    // font-family: "Open Sans";
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 19px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #2c2c39;
                }
            }
        }

        .search-tag-modal-footer {
            box-shadow: 0px -4px 13px rgba(0, 0, 0, 0.06);
            margin-top: 1rem;
            padding-right: 1rem;
        }

        .search-tag-icon {
            transform: rotate(270deg);
        }
    }

    .SelectedImagesPreview {
        .PostImage {
            position: relative;

            .more {
                position: absolute;
                color: white;
                z-index: 1;
                background-color: #0003;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                font-size: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .delete {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: white;
                right: 0px;
                top: 0px;
                width: 21px;
                height: 21px;
                cursor: pointer;
                font-size: 16px;
                background-color: #0007;
            }
        }
    }

    .SelectedVideoPreview {
        .SelectedVideo {
            position: relative;

            .remove {
                position: absolute;
                border-radius: 50%;
                background-color: red;
                width: 21px;
                height: 21px;
                color: white;
                font-size: 24px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                top: 10px;
                right: 10px;
                display: flex;
            }
        }
    }

    .SelectedDocumentPreview {
        .remove {
            position: absolute;
            border-radius: 50%;
            background-color: red;
            width: 21px;
            height: 21px;
            color: white;
            font-size: 24px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            top: -9px;
            right: -9px;
            display: flex;
        }
    }

    .PostCard {
        position: relative;
        min-width: 476px;

        // max-width: 100%;
        // overflow: hidden;
        .PostImageFullPreview {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #0003;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 200;

            div.holder {
                padding: 21px;
                position: relative;

                img {
                    max-height: 95vh;
                }

                .close-button {
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    background-color: white;
                }
            }
        }

        .PostHeader {
            position: relative;

            .dropdown {
                position: absolute;
                top: 32px;
                right: 12px;
                z-index: 1;

                // width: 150px;
                .dropdown-button {
                    cursor: pointer;
                    display: block;
                    width: 100%;
                }
            }

            .PostLiveStatus {
                display: flex;
                align-items: center;
                border-radius: 15px;
                margin: 0 6px;

                img {
                    width: 21px;
                    margin: 4px;
                    margin-left: 8px;
                    margin-right: 0;
                }

                span {
                    font-weight: 600;
                    font-size: small;
                    padding: 2px 5px;
                }

                &.live {
                    background-color: red;

                    span {
                        color: white;
                    }
                }

                &.ended {
                    background-color: #ddd;

                    span {
                        color: #232323;
                    }
                }
            }
        }

        .PostDescription,
        .PostComment {

            h1,
            h2,
            h3,
            h4,
            h5 {
                font-size: initial;
                font-weight: 700;
            }

            h1 {
                font-size: x-large;
            }

            h2 {
                font-size: large;
            }

            h3 {
                font-size: larger;
            }

            h4 {
                font-size: medium;
            }

            p {
                margin: 12px 0;
                word-break: break-word;
            }

            code:not(pre > code) {
                background: hsla(0, 0%, 78%, 0.3);
                border: 1px solid var(--theme-border-color);
                border-radius: 4px;
                padding: 0 3px;
            }

            pre {
                white-space: pre-wrap;
                padding: 1em;
                // color: #353535;
                background: hsla(0, 0%, 68%, 0.3);
                border: 1px solid var(--theme-border-color);
                border-radius: 2px;
                text-align: left;
                direction: ltr;
                tab-size: 4;
                white-space: pre-wrap;
                font-style: normal;
                min-width: 200px;
            }


            blockquote {
                overflow: hidden;
                padding-right: 1.5em;
                padding-left: 1.5em;
                margin-left: 0;
                margin-right: 0;
                font-style: italic;
                border-left: 5px solid #ccc;
            }

            &.has-more {
                .NL2BR {
                    overflow: hidden;
                    max-height: 140px;
                    position: relative;

                    &::after {
                        position: absolute;
                        content: "";
                        display: block;
                        height: 24px;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-image: linear-gradient(#fff0, var(--theme-surface-color));
                    }
                }

                .read-more {
                    cursor: pointer;
                }
            }
        }

        .PostImages {
            .PostImage {
                .more {
                    position: absolute;
                    color: white;
                    z-index: 1;
                    background-color: #0003;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    font-size: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .PostQuestion {
            .option {
                display: flex;
                align-items: center;

                .label {
                    background-color: #ccc;
                    width: 21px;
                    height: 21px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    font-weight: 500px;
                    margin-right: 12px;
                }

                padding: 5px 10px;
                margin: 5px 0;
                cursor: pointer;

                // &:hover {
                //     background-color: #eee;
                // }
                &.correct {
                    color: white;
                    background-color: #28b446;

                    .label {
                        color: black;
                    }

                    &.hover {
                        background-color: #28b446;
                    }
                }

                &.selected {
                    background-color: #eee;

                    &.hover {
                        background-color: #c42430;
                    }
                }

                &.incorrect {
                    color: white;
                    background-color: #c42430;

                    .label {
                        color: black;
                    }

                    &.hover {
                        background-color: #c42430;
                    }
                }
            }
        }

        .PostProcessing {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 2;
            background-color: #fffb;
        }

        ol,
        ul {
            list-style: initial !important;
            margin: initial;
            padding: 0.75rem !important;
            padding-left: 2rem !important;
        }

        ol {
            list-style: decimal !important;
        }

        .react-datepicker-popper {

            ol,
            ul {
                padding: 0.75rem !important;
                padding-left: 0rem !important;
            }
        }
    }

    .RealtimePostCard {
        position: relative;

        .msg-time {
            display: none;
        }

        &:hover {
            .msg-time {
                display: inline;
            }
        }

        .realtimepost-options {
            display: none;
            position: absolute;
            top: -30px;
            right: 5px;

            .realtimepost-option {}
        }

        &:hover {
            .realtimepost-options {
                display: block;
            }
        }
    }

    .SquarePostCard {
        position: relative;

        .PostProcessing {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 2;
            background-color: #fffb;
        }
    }

    .AppContainer {
        display: grid;
        grid-template-columns: 300px 1fr 300px;
        grid-template-areas: "sidebar_left main sidebar_right";
        grid-column-gap: 10px;
        max-width: 1340px;
        margin: 91px auto auto;
        position: relative;

        &.two-column {
            grid-template-columns: 300px 1fr;
        }

        &.three-column-with-community-switcher {
            grid-template-columns: 63px 300px 1fr 300px;
            grid-template-areas: "community_switcher sidebar_left main sidebar_right";

            &.two-column {
                grid-template-columns: 63px 300px 1fr;
                grid-template-areas: "community_switcher sidebar_left main";
            }
        }

        &.one-column {
            grid-template-columns: none;
            display: block;
        }

        &.noTopPadding {
            margin: auto auto;
        }

        .SideCommunitySwitcher {
            grid-area: community_switcher;
            // left: -77px;
            z-index: 100;
            position: relative;
            width: 100%;
            // max-width: 300px;
            overflow-y: auto;

            .sidecommunity-list {
                padding: 10px 5px;
                padding-right: 10px;
                position: fixed;
                overflow-y: auto;
                height: calc(100vh - 91px);

                .CommunityButton {
                    position: relative;

                    &.active {
                        &::after {
                            position: absolute;
                            display: block;
                            content: "";
                            height: 100%;
                            width: 5px;
                            left: calc(100% + 5px);
                            background-color: $pensilBlue;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }
                }
            }
        }

        .Sidebar {
            grid-area: "sidebar_left";
            max-width: 300px;
            position: relative;
            z-index: 120;

            .sidebar-content {
                position: fixed;
                width: 100%;
                height: calc(100vh - 91px);
                max-width: 300px;
                overflow-y: auto;
            }

            top: 0;
            left: 0;
            bottom: 0;
            overflow-y: auto;
            overflow-x: hidden;


            .create-group-button {
                padding: 4px;
                border-radius: 3px;
                border: 1px solid #aaa;
                cursor: pointer;

                img.inactive {
                    filter: opacity(0.5);
                }

                &.active,
                &:hover {

                    // background-color: #0445fe;
                    // color: white;
                    .inline-block {
                        border-color: #0445fe;
                    }
                }

                &.img {
                    img.active {
                        display: none;
                    }

                    img.inactive {
                        display: block;
                    }

                    &.active,
                    &:hover {
                        img.active {
                            display: block;
                        }

                        img.inactive {
                            display: none;
                        }
                    }
                }
            }

            .other-links,
            .group-links {
                .group-heading {
                    color: #8a8a8a;
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                }

                .create-section-button {
                    // padding: 8px;
                    border-radius: 6px;
                    cursor: pointer;

                    &.img {
                        img.active {
                            display: none;
                        }

                        img.inactive {
                            display: block;
                        }

                        &.active,
                        &:hover {
                            img.active {
                                display: block;
                            }

                            img.inactive {
                                display: none;
                            }
                        }
                    }
                }

                .GroupLinkButton {
                    position: relative;

                    .locked {
                        position: absolute;
                        left: 5px;
                        width: 12px;
                        z-index: 10;
                        top: 12px;
                    }

                    .expander {
                        position: absolute;
                        content: "";
                        display: block;
                        width: 0px;
                        height: 0px;
                        border: 4px solid #ddd;
                        top: 15px;
                        left: 18px;
                        z-index: 10;
                        border-top-color: transparent;
                        border-left-color: transparent;
                        transform: rotate(-45deg);
                        cursor: pointer;
                    }

                    &.expanded {
                        .expander {
                            top: 12px;
                            left: 21px;
                            transform: rotate(45deg);
                        }
                    }

                    .GroupLink {
                        font-weight: 600;
                        padding: 8px;
                        // color: #535353;
                        white-space: nowrap;
                        overflow: hidden;
                        cursor: pointer;
                        text-overflow: ellipsis;
                        border-radius: 4px;
                        position: relative;

                        // &:hover:not(.bg-theme-primary) {
                        //     background-color: #E6EDF1;
                        // }
                        .group-name {
                            font-weight: bold;
                            // color: black;
                        }

                        span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: calc(100%);
                        }

                        &.img {
                            img.active {
                                display: none;
                            }

                            img.inactive {
                                display: block;
                            }

                            &.active,
                            &:hover {
                                img.active {
                                    display: block;
                                }

                                img.inactive {
                                    display: none;
                                }
                            }
                        }

                        .GroupEllipsisIcon {
                            opacity: 0;
                        }

                        &:hover {
                            overflow: unset !important;

                            .GroupEllipsisIcon {
                                // display: block !important;
                                opacity: 1 !important;
                            }
                        }
                    }
                }

                .group-link-tab {
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    padding: 8px 0px 8px;
                    // padding-left: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    cursor: pointer;
                    text-overflow: ellipsis;
                    position: relative;
                    border-radius: 4px;

                    .emoji {
                        width: 21px;
                        text-align: center;
                        display: block;
                        margin: 0 4px;

                        &.hash {
                            font-size: large;
                            font-weight: bold;
                        }
                    }

                    .SectionEllipsisIcon {
                        opacity: 0;
                        margin-left: auto;
                    }

                    // &:hover:not(.bg-theme-primary) {
                    //     background-color: #E6EDF1;
                    // }
                    &:hover {
                        overflow: unset !important;

                        .SectionEllipsisIcon {
                            // display: block !important;
                            opacity: 1 !important;
                            margin-left: auto;
                        }
                    }

                    .notification-unread-count {
                        position: absolute;
                        background-color: var(--theme-primary-color);
                        color: white;
                        padding: 10px;
                        display: flex;
                        width: 16px;
                        justify-content: center;
                        align-items: center;
                        top: 14px;
                        right: 8px;
                        height: 16px;
                        border-radius: 5px;
                        font-size: smaller;
                    }
                }
            }

            .personal-group-links {
                .personal-group-link-tab {
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    padding-left: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    cursor: pointer;
                    text-overflow: ellipsis;
                    position: relative;
                    border-radius: 6px;

                    .emoji {
                        width: 21px;
                        text-align: center;
                        display: block;
                        margin: 0 4px;

                        &.hash {
                            font-size: large;
                            font-weight: bold;
                        }
                    }

                    .SectionEllipsisIcon {
                        opacity: 0;
                        margin-left: auto;
                    }

                    &:hover {
                        overflow: unset !important;

                        .SectionEllipsisIcon {
                            // display: block !important;
                            opacity: 1 !important;
                            margin-left: auto;
                        }
                    }

                    .notification-unread-count {
                        position: absolute;
                        background-color: var(--theme-primary-color);
                        color: white;
                        padding: 8px;
                        display: flex;
                        width: 16px;
                        justify-content: center;
                        align-items: center;
                        top: 14px;
                        right: 8px;
                        height: 16px;
                        border-radius: 5px;
                        font-size: smaller;
                    }
                }
            }

            .personal-group-links {
                .personal-group-link-tab {
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    padding-left: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    cursor: pointer;
                    text-overflow: ellipsis;
                    position: relative;
                    border-radius: 6px;

                    .emoji {
                        width: 21px;
                        text-align: center;
                        display: block;
                        margin: 0 4px;

                        &.hash {
                            font-size: large;
                            font-weight: bold;
                        }
                    }

                    .SectionEllipsisIcon {
                        opacity: 0;
                        margin-left: auto;
                    }

                    &:hover {
                        overflow: unset !important;

                        .SectionEllipsisIcon {
                            // display: block !important;
                            opacity: 1 !important;
                            margin-left: auto;
                        }
                    }

                    .notification-unread-count {
                        position: absolute;
                        background-color: var(--theme-primary-color);
                        color: white;
                        padding: 8px;
                        display: flex;
                        width: 16px;
                        justify-content: center;
                        align-items: center;
                        top: 14px;
                        right: 8px;
                        height: 16px;
                        border-radius: 5px;
                        font-size: smaller;
                    }
                }
            }
        }

        [class*="Page"] {
            grid-area: main;

            .main-content {
                // max-width: 540px;
                margin: auto;
                position: relative;

                .no-post {
                    min-width: 476px;
                }
            }
        }

        .secondary-sidebar {

            grid-area: sidebar_right;
            max-width: 300px;
            position: relative;
            top: -91px !important;

            .secondary-sidebar-content {
                position: fixed;
                width: 100%;
                height: calc(100vh - 0px);
                max-width: 300px;
                overflow-y: auto;
                padding-top: 91px;
            }



            top: 0;
            left: 0;
            bottom: 0;
            overflow-y: auto;
            overflow-x: hidden;
            // z-index: 100;

            .sidebar-heading {
                // font-family: Open Sans;
                font-family: "Nunito", sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #8a8a8a;
            }
        }
    }

    .CommunityHomePage {
        .main-content {
            width: 100%;

            .GroupCard {
                .color {
                    background-size: cover;
                    background-position: center;
                }

                .GroupBanner {
                    padding-top: 41.66%;
                }
            }

            .main {
                display: grid;
                grid-template-columns: 1fr 00px;
                grid-template-areas: "main_content sidebar_right";
            }

            @media screen and (max-width: 540px) {

                .main {
                    grid-template-columns: 1fr;
                }

                .secondary-sidebar {
                    display: none;
                }
            }

            .CommunityPostTab {
                position: relative;

                .sort-order-selector-holder {
                    position: absolute;
                    top: -36px;
                    display: flex;
                    align-items: center;

                    .sort-order-selector {
                        padding: 3px 6px;
                        background-color: transparent;

                        &:hover {
                            background-color: white;
                        }
                    }
                }

                .PostList {
                    max-width: 640px;

                    .post-card {
                        &:not(:last-child) {
                            border-bottom: 1px solid #e0e0e0;
                        }
                    }
                }

                .HomeWidget {
                    max-width: 364px;
                    min-width: 364px;
                }
            }
        }

        .CommunityInfo {
            background-repeat: no-repeat no-repeat;
            min-height: 50px;

            .CommunityBannerEditButton {
                .Modal {
                    .Card {
                        margin: 2rem auto;
                    }
                }

                .banner-selector-holder {
                    margin: 1rem 0;
                    padding: 5px;
                    border-width: 1px;
                    border-style: dashed;

                    .banner-selector {
                        background-color: #e5e5e5;
                        // height: 180px;
                        display: flex;
                        flex-direction: column;
                        background: right center;
                        background-size: contain;
                        background-repeat: no-repeat no-repeat;
                    }
                }
            }

            .NL2BR {
                flex-grow: 1;
            }
        }
    }

    .GroupPage {
        .GroupInfo {
            .dropdown-holder {
                position: relative;

                .dropdown {
                    width: 180px;
                    position: absolute;
                    right: 5px;
                    z-index: 5;

                    .dropdown-button {
                        cursor: pointer;

                        &:hover {
                            background-color: #eee;
                        }
                    }
                }
            }

            .GroupBanner {
                padding-top: 41.66%;
            }
        }

        .GroupSection {
            position: relative;

            .loader {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fffa;
            }
        }

        +.secondary-sidebar {
            .GroupMemberList {
                .group-member-list-item {
                    .IconMenu {
                        visibility: hidden;
                    }

                    &:hover {
                        .IconMenu {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .ShareTextModal {
        .copied {
            position: relative;
            padding-left: 12px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 12px;
                height: 6px;
                left: -5px;
                top: 6px;
                background-color: transparent;
                border: 2px solid white;
                border-right: 0;
                border-top: 0;
                transform: rotate(-45deg);
            }
        }
    }

    .QuizOptions {
        .QuizOption {}
    }

    .UploadProgressBar {
        background-color: #0001;

        .upload-progress {
            height: 4px;
            border-radius: 3px;
            background-color: var(--theme-primary-color);
        }
    }

    .ToastNotifier {
        position: fixed;
        right: 1rem;
        bottom: 4.75rem;
        z-index: 1000;

        .toast {
            .toast-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 240px;

                .toast-message {
                    font-size: smaller;
                }
            }

            .toast-delete {
                display: block;
                width: 20px;
                // color: #fffa;
                cursor: pointer;
                font-size: 1.5rem;
                text-align: center;
                margin-left: 1rem;
            }
        }
    }

    // form controls
    .Radio {
        position: relative;
        padding-left: 1.5rem;
        transition: all 2s ease;

        .radio-comp-1 {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 3px;
            border-radius: 50%;
            border-style: solid;
            border-width: 1.5px;
            // border-color: var(--theme-heading-2-text-color);
        }

        &:hover,
        &.selected {
            .radio-comp-1 {
                border-style: solid;
                border-width: 1.5px;
            }

            .radio-comp-2 {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                left: 1.5px;
                top: 1.47px;
                border-radius: 50%;
            }
        }
    }

    .Checkbox {
        position: relative;
        padding-left: 1.5rem;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            // top: 3px;
            border-radius: 3px;
            border: 1px solid #5c5c5c;
        }

        &:hover {
            &::before {
                border: 1px solid var(--theme-primary-color);
            }
        }

        &.selected {
            &::before {
                border: 1px solid $pensilBlue;
                background-color: $pensilBlue;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 10px;
                height: 5px;
                left: 3px;
                top: 4px;
                background-color: transparent;
                border: 1px solid white;
                border-right: 0;
                border-top: 0;
                transform: rotate(-45deg);
            }
        }

        &.semiSelected {
            &::before {
                border: 1px solid $pensilBlue;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 8px;
                height: 0px;
                left: 4px;
                top: 10px;
                background-color: transparent;
                border: 2px solid $pensilBlue;
                border-radius: 5px;
                border-right: 0;
                border-top: 0;
                border-left: 0;
                transform: rotate(0deg);
            }
        }

        &.disabled {
            &::before {
                border: 1px solid var(--theme-subtitle-2-text-color);
                background-color: var(--theme-disabled-color);
            }
        }
    }

    .Switch {
        position: relative;
        padding-left: 1.5rem;

        &.no-children {
            content: "";
            height: 22px;
            width: 30px;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 10px;
            left: 0;
            top: 6px;
            border-radius: 5px;
            background-color: #aaa;
            border: 1px solid var(--theme-border-color);
        }

        &::after {
            transition: all 0.4s;
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: -3px;
            right: auto;
            top: 3px;
            border-radius: 50%;
            background-color: #777;
            box-shadow: 0px 0px 3px 0px #7773;
        }

        &:hover {
            &::after {
                background-color: #666;
                box-shadow: 0px 0px 4px 0px #7773;
            }
        }

        &.selected {
            &::after {
                background-color: $pensilBlue;
                left: 13px;
            }
        }

        &.alt {
            transition: all 0.4s;

            &::before {
                width: 38px;
                height: 20px;
                left: 0;
                top: 2px;
                background-color: #7b7b7b;
                border-radius: 10px;
            }

            &::after {
                top: 5px;
                left: 3px;
                height: 14px;
                width: 14px;
                background-color: var(--theme-on-primary-color);
            }

            &.selected {
                &::before {
                    background-color: var(--theme-primary-color);
                }

                &::after {
                    left: auto;
                    right: -5px;
                }
            }
        }
    }

    .CommunityWelcomeMessages {
        .pageicon {
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 2px;
            background-color: #ddd;
            margin: 2px;

            &.active {
                background-color: var(--theme-primary-color);
                width: 15px;
            }
        }
    }

    .CommunitySubscriptionPage {
        table {
            td:not(:first-child):not(:nth-child(2)) {
                border-left: 1px solid #ddd;
            }

            tbody {
                tr:not(:first-child) {
                    td {
                        border-top: 1px solid #ddd;
                    }
                }

                tr:last-child td {
                    padding-bottom: 2rem;
                }
            }
        }
    }

    .UploadFile {
        .empty-preview {
            border: 1px dashed #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .PreviewSelected {
            position: relative;

            .delete {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: white;
                right: 5px;
                top: 5px;
                width: 21px;
                height: 21px;
                cursor: pointer;
                font-size: 16px;
                background-color: #0007;
            }
        }
    }

    .RichTextEditor {
        .ck-content .mention {
            background-color: var(--theme-primary-color-light);
            color: var(--theme-primary-color);
        }
    }

    .ck.ck-list__item .ck-button.ck-on {
        color: var(--theme-primary-color);
        background-color: var(--theme-primary-color-light);
    }

    // classic editor
    // .RichTextEditor {
    //     .ck.ck-reset.ck-editor {
    //         display: -webkit-box;
    //         display: -moz-box;
    //         display: -ms-flexbox;
    //         display: -webkit-flex;
    //         display: flex;
    //         -webkit-flex-direction: column-reverse;
    //         -moz-flex-direction: column-reverse;
    //         -ms-flex-direction: column-reverse;
    //         flex-direction: column-reverse;
    //     }
    //     .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
    //         min-height: 200px !important;
    //     }
    //     .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
    //     .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    //         border-radius: var(--ck-border-radius);
    //         border-bottom-left-radius: 0;
    //         border-bottom-right-radius: 0;
    //     }
    //     .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    //         border-top: 0;
    //         border-bottom: 1px solid var(--ck-color-toolbar-border);
    //     }
    //     .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    //         border-radius: var(--ck-border-radius);
    //         border-top-left-radius: 0;
    //         border-top-right-radius: 0;
    //     }
    // }
    .ck.ck-editor__editable pre[data-language]:after {
        display: none;
    }

    //select options
    select {
        border: none;
        border-radius: 10px;
        padding: 0 10px;
        cursor: pointer;
    }

    select option {
        font-weight: normal;
        font-size: 14px;
        background-color: var(--theme-surface-color);
    }

    .CreatePostEditor .RichTextEditor .ck.ck-content {
        min-height: 200px !important;
        max-height: none !important;
        border: 1px solid var(--theme-border-color);
        border-radius: var(--ck-border-radius);
    }

    // ballon editor
    .RichTextEditor {


        .RichEditorComment {
            min-height: 50px !important;
            border: 1px solid var(--theme-border-color);
            border-radius: var(--ck-border-radius);
        }

        .ck.ck-content {
            min-height: 200px !important;
            max-height: calc(100vh - 200px);
            border: 1px solid var(--theme-border-color);
            border-radius: var(--ck-border-radius);
        }

        &.noBorder {
            .ck.ck-content {
                border: 0;
            }

            .ck.ck-editor__editable:not(.ck-editor_nested-editable).ck-focused {
                box-shadow: none;
            }

            .ck.ck-editor__editable_inline {
                padding: 0;
            }
        }

        &.minHeightDefault {
            .ck.ck-content {
                min-height: 30px !important;
                border: 0;
            }
        }

        &.maxHeightInfinite {
            .ck.ck-content {
                max-height: none;
            }
        }

        // Editor image z-index
        .ck.ck-editor__editable .image-inline.ck-widget_selected,
        .ck.ck-editor__editable .image.ck-widget_selected {
            z-index: 0;
        }

        &.placeholder\:font-md {
            .ck-placeholder::before {
                font-size: medium;
                color: var(--theme-subtitle-2-text-color);
            }
        }

        &.placeholder\:font-md {
            .ck-placeholder::before {
                font-size: medium;
                color: var(--theme-subtitle-2-text-color);
            }
        }

        ol,
        ul {
            list-style: initial !important;
            margin: initial;
            padding: 0.75rem !important;
            padding-left: 2rem !important;
        }

        ol {
            list-style: decimal !important;
        }

    }

    .UserWallPage {
        .UserProfilePicFullPreview {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #0003;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 200;

            div.holder {
                padding: 21px;
                position: relative;

                img {
                    max-height: 95vh;
                }

                .close-button {
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    background-color: white;
                }
            }
        }

        .main-content {
            width: 100%;
            border-radius: 4px;
        }

        .user-detail-card {
            border-radius: 4px;
        }
    }

    .NotificationQuickView {
        position: relative;

        .notification-button-holder {
            position: relative;

            .notification-unread-count {
                position: absolute;
                background-color: var(--theme-primary-color);
                color: white;
                padding: 3px;
                display: flex;
                width: 16px;
                justify-content: center;
                align-items: center;
                top: 4px;
                right: 8px;
                height: 16px;
                border-radius: 50%;
                font-size: xx-small;
            }
        }

        .notification-dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            width: 300px;

            .notification-list-holder {
                max-height: 320px;
                overflow-y: scroll;

                .notification-item {}
            }
        }
    }

    /*
██████╗  ██████╗ ███████╗████████╗    ██████╗ ███████╗██╗      █████╗ ████████╗███████╗██████╗ 
██╔══██╗██╔═══██╗██╔════╝╚══██╔══╝    ██╔══██╗██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██████╔╝██║   ██║███████╗   ██║       ██████╔╝█████╗  ██║     ███████║   ██║   █████╗  ██║  ██║
██╔═══╝ ██║   ██║╚════██║   ██║       ██╔══██╗██╔══╝  ██║     ██╔══██║   ██║   ██╔══╝  ██║  ██║
██║     ╚██████╔╝███████║   ██║       ██║  ██║███████╗███████╗██║  ██║   ██║   ███████╗██████╔╝
╚═╝      ╚═════╝ ╚══════╝   ╚═╝       ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝                                                                                    
    */

    .CreatePostCard {
        .Card {
            border-radius: 6px;
        }

        p {
            margin: 12px 0;
        }
    }

    .CreateComment {
        input {
            padding-right: 36px;
            flex-grow: 1;
        }

        textarea {
            resize: none;
        }

        .preview-image {
            max-width: 150px;
            max-height: 150px;
            margin-top: 1rem;
            position: relative;

            .remove {
                position: absolute;
                border-radius: 50%;
                background-color: red;
                width: 21px;
                height: 21px;
                color: white;
                font-size: 24px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                top: -8px;
                right: -8px;
                display: flex;
            }
        }

        .preview-video {
            max-width: 350px;
            // max-height: 350px;
            margin-top: 1rem;
            position: relative;

            .remove {
                position: absolute;
                border-radius: 50%;
                background-color: red;
                width: 21px;
                height: 21px;
                color: white;
                font-size: 24px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                top: -8px;
                right: -8px;
                display: flex;
            }
        }

        .comment-button {
            flex-shrink: 0;
        }
    }

    .PostComment {
        word-break: break-word;
        position: relative;

        .processing {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fffa;
        }
    }

    .avatar-thread-border {
        height: calc(100% - 7rem);
    }

    /*
components
*/
    .IconMenu {
        position: relative;

        .dropdown-item {
            &:hover {
                background-color: #0000000a;
            }
        }

        .dropdown {
            position: absolute;
            // width: 180px;
            right: 0;
            display: none;
            z-index: 1;

            .dropdown-label {
                cursor: pointer;
                padding: 8px 12px;
                font-size: small;

                &:hover {
                    background-color: #0000000a;
                }
            }
        }

        &.active {
            .dropdown {
                display: block;
            }
        }
    }

    .MeetingPage {
        position: fixed;
        z-index: 123;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: black;

        .message {
            color: white;
        }
    }

    .CommunityLinks {
        .link {
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 8px;
            padding-left: 12px;
            color: #535353;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            border-radius: 6px;

            .emoji {
                width: 21px;
                text-align: center;
                display: block;
                margin: 0 4px;

                &.hash {
                    font-size: large;
                    font-weight: bold;
                }
            }

            // &.active,
            // &:hover {
            //     color: white;
            // }
        }
    }

    .PictureUpdate {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
        flex-direction: column;

        .upload-control {
            border: 2px dashed #ddd;
            border-radius: 5px;
            overflow: hidden;
            width: 96px;
            flex-direction: column;
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // karma button
    .KarmaButton {
        background: linear-gradient(180deg, #fbe560 0%, #fbe560 45.31%, #fdf2ad 56.77%, #fbe97a 100%);
        box-shadow: 0 1px 3px 0 rgba(233, 209, 69, 1), 0 1px 2px 0 rgba(233, 209, 69, 1);
    }

    // LeaderboardPage
    .LeaderboardPage-Container {
        // height: calc(100vh - 150px);
        // background-color: white;
        overflow: scroll;

        .LeaderboardPage {
            table {
                border-spacing: 0;

                thead {
                    tr {
                        border-bottom: unset !important;

                        :first-child {
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }

                        :last-child {
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }

                        th {
                            padding: 0.5rem;
                            font-weight: 700;
                            opacity: 0.6;
                        }
                    }
                }

                tbody {
                    tr {
                        margin: 0;
                        padding: 0.5rem;
                        border-bottom: 1px solid #00000011;
                        //   border-right: 1px solid black;
                        text-align: center;
                    }

                    td {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }

    // embed related
    .EmbedApp {

        .EmbedPostList,
        .EmbedPostDetail {
            max-width: 100%;
            min-width: 300px;

            .PostCard {
                box-sizing: border-box;
                min-width: initial;
            }
        }
    }

    .EmbedLinkModal {
        word-break: break-all;

        code {
            word-break: break-all;
        }
    }

    .JoyRide {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        mix-blend-mode: hard-light;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;

        .spotlight {
            position: absolute;
            background-color: gray;
            box-shadow: 0 0 15px 0 #0003;
            border-radius: 6px;
        }

        &.prologue-holder {
            mix-blend-mode: initial;
        }

        .prologue {
            z-index: 10001;
            max-width: 480px;
            margin: auto;
            background: white;
            padding: 2.5rem 2rem;
            margin-top: 2rem;
            border-radius: 10px;
            box-shadow: 0px 0px 25px 0px #00000029;
            overflow: hidden;
            position: relative;

            .main-logo {
                max-width: 120px;
                max-height: 42px;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -5rem;
                width: 15rem;
                height: 13rem;
                border: 4rem solid #f2f2f2;
                border-top-width: 0;
                border-right-width: 0;
                left: 50%;
                transform: rotate(-45deg);
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -3.8rem;
                width: 20rem;
                height: 8rem;
                border: 2.4rem solid #28b446;
                border-top: none;
                border-right: none;
                right: -7.2rem;
                transform: rotate(-45deg);
            }

            .content {
                position: relative;
                z-index: 1;

                .address {
                    color: #28b446;
                    font-weight: bold;
                    font-size: large;
                }

                .success-message {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .JoyRideDescription {
        transition: all 0.4s;
        z-index: 10001;
        position: fixed;
        padding: 0.75rem 1rem 1.5rem 1rem;
        position: fixed;
        box-shadow: 0 0 15px 0 #0003;
        border-radius: 6px;
        max-width: 480px;
        background-color: white;
    }

    .SmallAddButton {
        padding: 2px;
        border-radius: 3px;
        border: 1px solid #aaa;
        cursor: pointer;
        display: inline-block;

        img.inactive {
            filter: opacity(0.5);
        }

        &.active,
        &:hover {

            // background-color: #0445fe;
            // color: white;
            .inline-block {
                border-color: #0445fe;
            }
        }

        &.img {
            img.active {
                display: none;
            }

            img.inactive {
                display: block;
            }

            &.active,
            &:hover {
                img.active {
                    display: block;
                }

                img.inactive {
                    display: none;
                }
            }
        }
    }

    .SmallAddRoundButton {
        padding: 2px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        border: 1px solid #aaa;

        .img {
            img {
                height: 10px;
                width: 10px;
            }

            img.active {
                display: none;
            }

            img.inactive {
                display: block;
            }

            &.active,
            &:hover {
                img.active {
                    display: block;
                }

                img.inactive {
                    display: none;
                }
            }
        }
    }

    .Tick {
        background-color: red;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        position: relative;

        &::before {
            display: block;
            content: "";
            width: 0;
            height: 15px;
            border-right: 3px solid white;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 9px;
        }

        &::after {
            display: block;
            content: "";
            width: 0;
            height: 15px;
            border-right: 3px solid white;
            transform: rotate(45deg);
            position: absolute;
            top: 3px;
            left: 9px;
        }

        &.success {
            background-color: green;

            &::before {
                display: none;
            }

            &::after {
                display: block;
                content: "";
                width: 10px;
                height: 15px;
                border: 3px solid white;
                border-top-color: transparent;
                border-left-color: transparent;
                transform: rotate(45deg);
                position: relative;
                top: auto;
                left: 5px;
            }
        }

        &.minimal {
            background-color: transparent;

            &::before {
                border-right-color: red;
            }

            &::after {
                display: block;
                border-right-color: red;
            }

            &.success {
                background-color: transparent;

                &::after {
                    border-right-color: green;
                    border-bottom-color: green;
                }
            }
        }
    }

    .CommunitySelectorPage {
        margin: 74px auto;

        .main-content {}
    }

    .UserManagementPage {
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-template-areas: "sidebar_left main";
        grid-column-gap: 10px;
        max-width: 1340px;
        position: relative;

        &.two-column {
            grid-template-columns: 300px 1fr;
        }
    }

    .PopupImageViewer,
    .PopupVideoPlayer {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #0003;
        z-index: 130;
        justify-content: center;
        align-items: center;

        video {
            height: auto;
            width: 100%;
        }

        .holder {
            width: 50%;
            position: relative;

            .close-button {
                position: absolute;
                top: -12px;
                right: -12px;
                width: 24px;
                height: 24px;
                font-size: large;
                background-color: #aaa;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background-color: white;
                }
            }
        }
    }

    .PopupImageViewer {
        .holder {
            max-width: 90%;
            width: auto;
            margin: 90px;
            max-height: 90%;
        }
    }

    .ExpiredActionPreventor {
        position: relative;

        .preventor-overlay {
            background-color: #fff0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .expired-action-preventor-popup {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 120;
        }
    }
}

// for 1200px and less
@media only screen and (max-width: 1200px) {
    body {
        .Header {
            .header-container {
                max-width: 900px;
                // grid-template-columns: 280px 1fr 200px;
                grid-template-areas: "main_logo search_bar other_options";
            }
        }

        .AppContainer {
            grid-template-columns: 300px 1fr;
            grid-template-areas: "sidebar_left main";
            max-width: 900px;

            &.two-column {
                grid-template-columns: 300px 1fr;
            }

            &.three-column-with-community-switcher {
                grid-template-columns: 300px 1fr;
                grid-template-areas: "sidebar_left main";

                &.two-column {
                    grid-template-columns: 300px 1fr;
                    grid-template-areas: "sidebar_left main";
                }
            }

            .SideCommunitySwitcher,
            .secondary-sidebar {
                display: none;
            }
        }
    }

    .CommunityPostTab {

        // Hide home widgets when screen width is less than 1200px
        .HomeWidget {
            display: none;
        }
    }
}

// for 990px and less
.mobile-only {
    display: none !important;
}

@media only screen and (max-width: 990px) {
    .mobile-only {
        display: block !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    body {
        .Header {
            .header-container {
                max-width: 600px;
                // grid-template-columns: 1fr 200px;
                grid-template-areas: "main_logo other_options";

                .mobile-header-hamburger {
                    display: flex;
                }

                .SearchBar {
                    display: none;
                }

                .MainLogo {
                    margin-left: 0px;
                }
            }
        }

        .CommunityMobileAppPopup {
            display: flex;
        }

        .AppContainer {
            grid-template-columns: 1fr;
            grid-template-areas: "main";
            max-width: 600px;

            &.two-column {
                grid-template-columns: 1fr;
            }

            &.three-column-with-community-switcher {
                grid-template-columns: 1fr;
                grid-template-areas: " main";

                &.two-column {
                    grid-template-columns: 1fr;
                }
            }

            .SideCommunitySwitcher {
                position: fixed;
                display: block;
                top: 73px;
                bottom: 0;
                left: -70px;
                width: 63px;
                transition: all 0.4s;

                .sidecommunity-list {
                    height: calc(100vh - 73px);
                }

                &.open {
                    left: 0;
                }
            }

            .Sidebar {
                position: fixed;
                display: block;
                top: 79px;
                left: -300px;
                bottom: 0px;
                transition: all 0.4s;

                .sidebar-content {
                    height: calc(100vh - 79px);
                    box-shadow: 0 0 10px 0 #0000;
                }

                &.open {
                    left: 0px;
                    z-index: 100;

                    &.no-switcher {
                        left: 0;
                    }

                    .sidebar-content {
                        box-shadow: 0 0 10px 0 #0003;
                    }
                }
            }
        }

        // .NCAC {
        //     // no community app container
        //     max-width: 900px;
        // }
        .UserManagementPage {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: "main";
            grid-column-gap: 10px;
            max-width: 1340px;
            position: relative;

            &.two-column {
                grid-template-columns: 1fr;
            }
        }
    }
}

// for 480px and less
@media only screen and (max-width: 480px) {
    body {
        .Header {
            .header-container {
                max-width: 100%;
                grid-template-areas: "main_logo other_options";
                // grid-template-columns: 1fr 145px;
                grid-column-gap: inherit;

                .OtherOptions {
                    .Avatar {
                        &.hide-on-mobile {
                            .name {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .AppContainer {
            max-width: 100%;

            [class*="Page"] {
                .main-content {
                    padding-left: 12px;
                    padding-right: 12px;

                    .no-post {
                        min-width: auto;
                    }

                    .CreatePostCard {
                        padding: 0;

                        .Card {
                            padding: 0.5rem;
                        }

                        &.active {
                            .Card {
                                position: relative;
                                margin: 0;
                                border-radius: 0;
                                min-height: 100vh;
                                padding-bottom: 64px;

                                .CreatePostFooter {
                                    position: absolute;
                                    bottom: 15px;
                                    left: 10px;
                                    right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .RealtimeSectionInfo {
                position: fixed;
                left: 0;
                top: 110px;
                right: 0;
            }

            .RealtimeSectionDetail {
                top: 169px;
                left: 0;
                right: 0;
                bottom: 0;
                height: auto;
                overflow-y: auto;
                position: fixed;
            }

            margin-left: 0;

            .Sidebar {
                top: 72px;

                .sidebar-content {
                    height: calc(100vh - 72px);
                }
            }
        }

        .CommunityHomePage {
            .CommunityInfo {
                min-width: auto;
                max-width: calc(100vw - 24px);
            }
        }

        .PostCard {
            min-width: auto;
            max-width: calc(100vw - 24px);

            // padding: 12px;
            .PostActions {
                .IconButton {
                    padding-left: 6px;
                    padding-right: 6px;
                    margin: 0;

                    .label {
                        font-size: 12px;
                        margin-right: 0;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

// Big calendar
.rbc-calendar {
    .rbc-month-view {
        border: 1px solid var(--theme-border-color);

        .rbc-header {
            border-left: 1px solid var(--theme-border-color);
            border-bottom: 1px solid var(--theme-border-color);
        }
    }

    .rbc-off-range-bg {
        background-color: var(--theme-background-color);
    }

    .rbc-today {
        background-color: unset !important;
    }

    .rbc-row-content {
        .rbc-row {
            margin-top: 3px;

            .rbc-now {
                .theme-headline-2 {
                    background-color: var(--theme-primary-color);
                    color: var(--theme-on-primary-color);
                    padding: 2px 3px;
                    border-radius: 50%;
                }
            }

            .rbc-row-segment {
                .rbc-event {
                    border-radius: 4px !important;
                }

                .rbc-show-more {
                    background-color: var(--theme-surface-color);
                    color: var(--theme-heading-2-text-color);
                }
            }
        }
    }

    .rbc-month-row {
        border-top: 1px solid var(--theme-border-color);

        .rbc-day-bg {
            border-left: 1px solid var(--theme-border-color);
        }
    }
}

// eventBannerImage
.eventBannerImage {
    padding-top: 56.25%;
    /* Aspect ratio 16/9 */
}

.event-content {
    position: fixed;
    width: 100%;
    height: calc(100vh - 150px);
    max-width: 300px;
    overflow-y: auto;
}

.event-content-list {
    width: 100%;
    height: calc(100vh - 200px);
    overflow-y: auto;
}

.event-list-container {
    height: calc(100vh - 120px);
}

// for 480px and less
@media only screen and (max-width: 480px) {
    .event-content {
        position: unset !important;
        max-width: unset !important;
        height: calc(100vh - 50px);
    }
}

// Checkbox Disabled
// .Checkbox:hover::before,
body .Checkbox.disabled-selected::before {
    border: 1px solid var(--theme-disabled-color);
    background-color: var(--theme-disabled-color);
}

.Checkbox.disabled-selected::after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 5px;
    left: 3px;
    top: 7px;
    background-color: transparent;
    border: 1px solid white;
    border-right: 0;
    border-top: 0;
    transform: rotate(-45deg);
}

// react-select dropdown css
.css-1s2u09g-control {
    background-color: var(--theme-surface-color) !important;
    border-color: var(--theme-border-color) !important;
    cursor: pointer !important;
    min-width: 8.8rem !important;
}

.css-1pahdxg-control {
    background-color: var(--theme-surface-color) !important;
    border-color: var(--theme-border-color) !important;
    box-shadow: unset !important;
    min-width: 8.8rem !important;
    cursor: pointer !important;
}

.css-1n7v3ny-option {
    background-color: var(--theme-disabled-color) !important;
    cursor: pointer !important;
}

.css-26l3qy-menu {
    width: 12rem !important;
}

.css-1hb7zxy-IndicatorsContainer {
    border-radius: 4px !important;
}

.css-tlfecz-indicatorContainer {
    border-radius: 4px;
}

.css-3iigni-container {
    border-radius: 4px;
}

.css-14el2xx-placeholder {
    color: var(--theme-default-text-color) !important;
    z-index: 1;
}

.css-1insrsq-control {
    background-color: var(--theme-surface-color) !important;
    border-width: 0px !important;
}

// Pagination CSS
.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 16px;
        line-height: 1.43;
        font-size: 13px;
        min-width: 32px;

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        .arrow {
            &::before {
                position: relative;
                content: "";
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
            }

            &.right {
                transform: rotate(45deg);
            }
        }

        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(0, 0, 0, 0.43);
                border-top: 0.12em solid rgba(0, 0, 0, 0.43);
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}

// .dropdown:focus-within .dropdown-menu {
//     opacity: 1;
//     transform: translate(0) scale(1);
//     visibility: visible;
// }

.customDropdown:focus-within .dropdown-menu {
    opacity: 1;
    transform: translate(0) scale(1);
    visibility: visible;
}

.tooltip:hover .label {
    opacity: 1;
    transform: translate(0) scale(1);
    visibility: visible;
}

.headings {
    h1 {
        display: block !important;
        font-size: 2em !important;
        margin-block-start: 0.67em !important;
        margin-block-end: 0.67em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }

    h2 {
        display: block !important;
        font-size: 1.5em !important;
        margin-block-start: 0.83em !important;
        margin-block-end: 0.83em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }

    h3 {
        display: block !important;
        font-size: 1.17em !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }

    h4 {
        display: block !important;
        font-size: 1em !important;
        margin-block-start: 1.33em !important;
        margin-block-end: 1.33em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }

    h5 {
        display: block !important;
        font-size: 0.83em !important;
        margin-block-start: 1.67em !important;
        margin-block-end: 1.67em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }

    h6 {
        display: block !important;
        font-size: 0.67em !important;
        margin-block-start: 2.33em !important;
        margin-block-end: 2.33em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        font-weight: bold !important;
    }
}