/* Background color */
html {
  background-color: var(--theme-background-color);
  color: var(--theme-default-text-color);
  border-color: var(--theme-border-color);
}

a {
  color: var(--theme-primary-color);
}

.theme-bg-primary {
  background-color: var(--theme-primary-color);
}

.theme-bg-default {
  background-color: var(--theme-background-color);
}

.theme-bg-surface {
  background-color: var(--theme-surface-color);
}

.theme-bg-on-surface {
  background-color: var(--theme-on-surface-color);
}

.theme-bg-disable {
  background-color: var(--theme-disabled-color);
}

.theme-bg-danger {
  background-color: var(--theme-danger-color);
}

.theme-bg-success {
  background-color: var(--theme-success-color);
}

.theme-bg-info {
  background-color: var(--theme-info-color);
}

.theme-bg-warning {
  background-color: var(--theme-warning-color);
}

/* Light background color*/
.theme-bg-primary-light {
  background-color: var(--theme-primary-color-light);
}

.theme-bg-heading-1 {
  background-color: var(--theme-heading-1-text-color);
}

.theme-bg-heading-2 {
  background-color: var(--theme-heading-2-text-color);
}

.theme-bg-subtitle-1 {
  background-color: var(--theme-subtitle-1-text-color);
}

.theme-bg-subtitle-2 {
  background-color: var(--theme-subtitle-2-text-color);
}

/* Text Theme */
.theme-text-default {
  color: var(--theme-default-text-color);
}

.theme-text-primary {
  color: var(--theme-primary-color);
}

.theme-text-on-primary {
  color: var(--theme-on-primary-color);
}

.theme-text-background {
  color: var(--theme-background-color);
}

.theme-text-heading-1 {
  color: var(--theme-heading-1-text-color);
}

.theme-text-heading-2 {
  color: var(--theme-heading-2-text-color);
}

.theme-text-subtitle-1 {
  color: var(--theme-subtitle-1-text-color);
}

.theme-text-subtitle-2 {
  color: var(--theme-subtitle-2-text-color);
}

.theme-text-danger {
  color: var(--theme-danger-color);
}

.theme-text-success {
  color: var(--theme-success-color);
}

.theme-text-info {
  color: var(--theme-info-color);
}

.theme-text-warning {
  color: var(--theme-warning-color);
}

/* Border */
.theme-border-primary {
  border-color: var(--theme-primary-color);
}

.theme-border-primary-light {
  border-color: var(--theme-primary-color-light);
}

.theme-border-default {
  border-color: var(--theme-border-color);
}

.theme-border-danger {
  border-color: var(--theme-danger-color);
}

.theme-border-heading-1 {
  border-color: var(--theme-heading-1-text-color);
}

.theme-border-heading-2 {
  border-color: var(--theme-heading-2-text-color);
}

.theme-border-subtitle-1 {
  border-color: var(--theme-subtitle-1-text-color);
}

.theme-border-subtitle-2 {
  border-color: var(--theme-subtitle-2-text-color);
}
.theme-border-warning {
  border-color: var(--theme-warning-color);
}
.theme-border-info {
  border-color: var(--theme-info-color);
}
.theme-border-success {
  border-color: var(--theme-success-color);
}

/* Hover Background  */
.hover\:theme-bg-default:hover {
  background-color: var(--theme-background-color);
}

.hover\:theme-bg-primary-light:hover {
  background-color: var(--theme-primary-light-color);
}

.hover\:theme-bg-disable:hover {
  background-color: var(--theme-disabled-color);
}
.hover\:theme-bg-danger:hover {
  background-color: var(--theme-danger-color);
}

/* Hover Border */
.hover\:theme-border-default:hover {
  border-color: var(--theme-border-color);
}

.hover\:theme-border-primary:hover {
  border-color: var(--theme-primary-color);
}

.hover\:theme-border-primary-light:hover {
  border-color: var(--theme-primary-light-color);
}
.hover\:theme-border-subtitle-2:hover {
  border-color: var(--theme-subtitle-2-text-color);
}

/* Hover text */
.hover\:theme-text-heading-1:hover {
  border-color: var(--theme-heading-1-text-color);
}
.hover\:theme-text-primary:hover {
  color: var(--theme-primary-color);
}

.hover\:theme-text-heading-2:hover {
  color: var(--theme-heading-2-text-color);
}

.hover\:theme-text-subtitle-1:hover {
  color: var(--theme-subtitle-1-text-color);
}

.hover\:theme-text-subtitle-2:hover {
  color: var(--theme-subtitle-2-text-color);
}
.hover\:theme-text-background:hover {
  color: var(--theme-background-color);
}

/* Focus */
.focus\:theme-border-primary:focus {
  border-color: var(--theme-primary-color);
}
